import * as React from "react"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Text from "../components/text/text"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core"
import about from "../components/about/about.json"

const AboutUs = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <div style={{ backgroundColor: "white", color: "white" }}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            lg={12}
            xl={12}
            style={{ paddingTop: "130px", paddingBottom: "20px" }}
          >
            <Text variant={"h3"} style={{ fontWeight: "bold" }}>
              About us
            </Text>
            <hr
              style={{
                color: "",
                height: "1px  ",
                paddingTop: "2px",
                borderWidth: "1px",
                backgroundColor: "#A72D3F",
                marginBottom: "30px",
                marginTop: "30px",
                width: smDown ? "35ch" : "",
              }}
            />{" "}
          </Grid>
        </Grid>

        {about.map(c => {
          return (
            <Grid container>
              <Grid item lx={6} lg={6} style={{ paddingBottom: "20px" }}>
                <Text variant={"body1"} style={{ fontWeight: "bold" }}>
                  {c.description}
                </Text>
              </Grid>

              <Grid
                item
                lx={6}
                lg={6}
                style={{ paddingLeft: smDown ? "0px" : "50px" }}
              >
                <img src={c.image} alt="none "></img>
              </Grid>
              <Grid item lx={6} lg={6}>
                <Text variant={"caption"} style={{ fontWeight: "bold" }}></Text>
              </Grid>
            </Grid>
          )
        })}
      </Container>
    </div>
  )
}
export default AboutUs
